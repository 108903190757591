import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
  SectionHeader,
} from "../components/styles"

import ArrowLeft from "../images/arrow-left.inline.svg"
import ArrowRight from "../images/arrow-right.inline.svg"

import { useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import SwipeableViews from "react-swipeable-views"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "index/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const sliderData = [
  {
    label: "Film & Television",
    text: [
      "I have worked in various capacities in film and television since 2000, focusing on projects which tackle a variety of challenging issues affecting our community.",
      "In 2013 I wrote and directed the four hour mini-series on capital punishment BETTER MAN for SBS (with Remy Hii, David Wenham, Bryan Brown) and in 2014 I directed SCHAPELLE for Nine. ",
      "Before then I wrote, directed and produced the features THE FINISHED PEOPLE tackling homelessness and drug abuse, MOTHER FISH and FALLING FOR SAHARA exploring the plight of refugees in modern Australia and in 2001 I wrote the short drama DELIVERY DAY examining the world of migrant sweatshops in western Sydney.",
    ],
  },
  {
    label: "Board Member",
    text: [
      "I am currently on the board of the Australia Council for the Arts, the most powerful arts governing body in Australia, and have been so since 2013.",
      "Previously I was a member of the Advertising Standards Board for 7 years.",
    ],
  },
  {
    label: "Arts",
    text: [
      "After graduating with a Bachelor of Arts from the University of Sydney, I have been involved in the arts for the past two decades initially working with companies such as Urban Theatre Projects, Parramatta Riverside Theatres and Powerhouse Youth Theatre. Since then I have continued to be an arts patron and current board member of the Australia Council for the Arts.",
    ],
  },
  {
    label: "Volunteering",
    text: [
      "I have previously worked as a volunteer with organisations such as Open Family Australia and the Vietnamese Community in Australia (NSW), teaching English, job-seeking skills and filmmaking to at-risk youths in Sydney. In 2000, I represented Australia at the Action 2000 Anti-Racism Conference in Canada.",
    ],
  },
  {
    label: "Speaker",
    text: [
      "Between 2006 - 2015 I travelled extensively across Australia and certain parts of south-east Asia as a keynote speaker, delivering 400+ presentations on resilience, antifragility and having a positive mindset."
    ],
  },
  {
    label: "Technology",
    text: [
      "In recent years I have been interested in the power of technology to fuel social change in a variety of areas including decision-making, mental health, education and the arts. As a result I've been exploring the many ways technology can be harnessed for long-term social good and have been championing ideas with this potential.",
    ],
  },
]

const Index = ({ data }) => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = sliderData.length
  const handleNext = () => {
    setActiveStep(prevActiveStep =>
      prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1
    )
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep =>
      prevActiveStep - 1 < 0 ? maxSteps - 1 : prevActiveStep - 1
    )
  }
  const handleStepChange = step => {
    setActiveStep(step)
  }
  return (
    <Layout>
      <SEO title="Home" />
      <Grid container style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <SectionHeader>ARTS. FILM. ENTREPRENEURSHIP. PHILANTHROPY.</SectionHeader>
            <PageHeader variant="h1">
              <span>I'm</span> Khoa. <br />
              <br />
              <span>Welcome to my website.</span>
            </PageHeader>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
      <Grid component="section" container>
        <Grid item xs={12}>
          <SectionHeader>A FEW THINGS ABOUT ME</SectionHeader>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {sliderData.map((slide, index) => (
              <Typography variant="h1" key={slide.label}>
                {slide.label}
              </Typography>
            ))}
          </SwipeableViews>
        </Grid>
        <Grid item container>
          <Grid item xs={12} md={3} lg={2}>
            <Box mb={3}>
              <Button onClick={handleBack} aria-label="Jump to next slide">
                {theme.direction === "rtl" ? <ArrowRight /> : <ArrowLeft />}
              </Button>
              <Button onClick={handleNext} aria-label="Jump to previous slide">
                {theme.direction === "rtl" ? <ArrowLeft /> : <ArrowRight />}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {sliderData.map((slide, index) => (
                <Box key={index}>
                  {slide.text.map((text, textIndex) => (
                    <Typography key={textIndex}>{text}</Typography>
                  ))}
                </Box>
              ))}
            </SwipeableViews>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Index
